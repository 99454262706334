import React, { useState } from 'react';
import axios from 'axios';

const Chat = () => {
  const [userMessage, setUserMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleMessageChange = (event) => {
    setUserMessage(event.target.value);
  };

  const sendMessage = async () => {
    if (userMessage.trim() === '') return;

    // Agregar mensaje del usuario al historial
    const newChatHistory = [...chatHistory, { author: 'Usuario', content: userMessage }];
    setChatHistory(newChatHistory);
    setLoading(true);

    try {
      // Realizar solicitud a la API con el formato esperado
      const response = await axios.post(
        'http://alusoft.ddns.net:8083/chat/',
        { message: userMessage }, // Enviar el mensaje dentro del objeto { "message": "mensaje" }
        {
          headers: {
          },
        }
      );

      // Agregar la respuesta del asistente al historial
      newChatHistory.push({ author: 'Asistente', content: response.data.message });
      setChatHistory(newChatHistory);
    } catch (error) {
      console.error('Error al obtener respuesta del asistente:', error);
      alert('Hubo un error al conectar con la API. Verifica que el servidor esté en ejecución.');
    } finally {
      setUserMessage('');
      setLoading(false);
    }
  };


  const handleInput = (event) => {
    const textarea = event.target;
    textarea.style.height = 'auto'; // Resetea la altura
    textarea.style.height = `${textarea.scrollHeight}px`; // Ajusta la altura según el contenido
  };

  var Msg;

  

  return (
    <div style={{ width: '70%' }} >
      <h3>Chat Asistente</h3>
      
      <div style={{ marginTop: '20px' }}>
        <h4>Conversación:</h4>
        <div style={{ textAlign:'center' }} >
          {chatHistory.map((message, index) => (
            <p key={index}>
            
            
             
                      
                <div style={{ width: '80%', textAlign:'left', }}><i>{message.author}:</i></div> 
                <pre   style={{ width: '80%', textAlign:'left', }}>{message.content}</pre>
               
            </p>
          ))}
        </div>
      </div>

      <div>
        <textarea style={{ borderRadius: '20px'}}
          value={userMessage}
          onChange={handleMessageChange}
          rows="3"
          placeholder="Escribe tu mensaje aquí..."
        />
        <br />
        <button onClick={sendMessage} disabled={loading}>
          {loading ? 'Enviando...' : 'Enviar'}
        </button>
      </div>

     
    </div>
  );
};

export default Chat;